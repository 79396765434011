const nav = () => {
  const mobileButton = document.querySelector('a.mobile-button');
  const menu = document.querySelector('.mobile-menu-cont');
  const regionLink = document.querySelector('.mobile-menu-row ul > li');
  const regionMenu = document.querySelector('.mobile-menu-row ul > li > ul');
  const bottom = document.getElementById('other');
  const mobileNav = document.getElementById('mobile-nav');

  function init() {
    mobileButton.addEventListener('click', toggleMenu);
    // regionLink.addEventListener('click', toggleSubMenu);
  }

  let toggleMenu = e => {
    e.preventDefault();
    if (menu.classList.contains('slideup')) {
      menu.className = menu.className.replace('slideup', '');
      menu.className += ' slidedown';
      bottom.setAttribute('style', 'height: 600px');
    } else {
      menu.className = menu.className.replace('slidedown', '');
      menu.className += ' slideup';
      bottom.removeAttribute('style', 'height: 600px');
      bottom.className += ' nav_up';
    }
  };

  let toggleSubMenu = e => {
    e.preventDefault();
    if (regionMenu.classList.contains('show')) {
      regionMenu.classList.remove('show');
    } else {
      regionMenu.classList.add('show');
    }
  };

  return init();
};

export default nav;
