/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap';
import 'rails-validator';
import nav from './nav';

(() => {
  window.app = {
    query(selectors) {
      return document.querySelector(selectors);
    },

    queryAll(selectors) {
      return document.querySelectorAll(selectors);
    },
    //http://stackoverflow.com/questions/19491336/
    //   get-url-parameter-jquery-or-how-to-get-query-string-values-in-js
    getUrlParameter(sParam) {
      const sPageURL = decodeURIComponent(window.location.search.substring(1));
      const sURLVariables = sPageURL.split('&');
      let sParameterName;
      let i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    },
  };

  document.addEventListener('DOMContentLoaded', nav);
})();
